import React from "react";
import { LinkedInIcon } from "../components/LinkedInIcon";
import { ProfileCard } from "../components/ProfileCard";
import { TwitterIcon } from "../components/TwitterIcon";
// import { NextTeamMemberCard } from "../components/NextTeamMemberCard";
import { Header } from "../components/Header";
import forth from "../static/images/forth.jpg";
import founders from "../static/images/founders.png";
import fontas from "../static/images/fontas.jpg";
import vasilis from "../static/images/vasilis.jpg";
import lefteris from "../static/images/lefteris.jpg";
import ioannis from "../static/images/ioannis.jpg";
import korina from "../static/images/korina.jpg";
import giannis from "../static/images/giannis.jpg";
import konstantinos from "../static/images/konstantinos.jpg";
import gepta from "../static/images/gepta.jpg";
import ipaterakis from "../static/images/ipaterakis.jpg";
import { Footer } from "../components/Footer";

const AboutPage = () => {
  return (
    <html lang='en'>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
        <title>About | Code BGP</title>
      </head>
      <body className="bg-gray-50">
      </body>
    </html>
  )
}

export default AboutPage
